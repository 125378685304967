const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, allSuitArrangementsWithSingleSuits, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, combinations, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
    //QQQQQ WIC KKKK LI - 35 points
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //QQQQQ
        newArr.push(createTiles({type: "any", value: "any", amount: 5}))

        //WIC
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "character", value: "any", amount: 1}))

        //KKKK
        newArr.push(createTiles({type: "any", value: "any", amount: 4}))

        //LI
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))

        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //BBB CCC DE FFFFF G - 35 points
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //BBB
        newArr.push(createTiles({type: "bamboo", value: "any", amount: 3}))

        //CCC
        newArr.push(createTiles({type: "character", value: "any", amount: 3}))

        //DE
        newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

        //FFFFF
        newArr.push(createTiles({type: "flower", amount: 5}))

        //G
        newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))

        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //45678 99999 10 11 - 1 or 2 suits, 40 points
    function(tiles = []) {
        allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //45678 99999
            newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 7, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 5}))

            //10 11
            newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 1}))
            newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 2}))
        })

        return {
            tiles,
            score: 40,
            concealed: false,
        }
    },
    //AA QQQQQ AA QQQQQ - 40 points
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //AA
        newArr.push(createTiles({type: "any", value: "any", amount: 2}))

        //QQQQQ
        newArr.push(createTiles({type: "any", value: "any", amount: 5}))

        //AA
        newArr.push(createTiles({type: "any", value: "any", amount: 2}))

        //QQQQQ
        newArr.push(createTiles({type: "any", value: "any", amount: 5}))

        return {
            tiles,
            score: 40,
            concealed: false,
        }
    },
    //55555 X 55555 IS 25 - 40 points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //55555
            newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 5}))

            //55555
            newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 5}))

            //IS
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))
            newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

            //25
            newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
            newArr.push(createTiles({type: suitOrder[2], value: 5, amount: 1}))
        })

        return {
            tiles,
            score: 40,
            concealed: false,
        }
    },
    //AA NA CCCCC OND AA - 50 points
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //AA
        newArr.push(createTiles({type: "any", value: "any", amount: 2}))

        //NA
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))

        //CCCCC
        newArr.push(createTiles({type: "character", value: "any", amount: 5}))

        //OND
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))

        //AA
        newArr.push(createTiles({type: "any", value: "any", amount: 2}))

        return {
            tiles,
            score: 50,
            concealed: false,
        }
    },
].map((func, index) => {
    let output = func()
    output.section = "Anaconda"
    output.sectionIndex = index + 1 //Sections indexed starting at 1. 
    output.handName = `${output.section} #${output.sectionIndex}`
    return new CardHand(output)
})

