const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, allSuitArrangementsWithSingleSuits, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, combinations, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
    //FF 222 555 999 999 - 3 suits (25 points)
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            allSuits.forEach((suit) => {
                let newArr = []
                tiles.push(newArr)

                //FF
                newArr.push(createTiles({type: "flower", amount: 2}))

                //222 555
                newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
                newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 3}))

                //999
                newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 3}))

                //999
                newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
            })
        })

        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //FFFF 888 888 9999
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            allSuits.forEach((suit) => {
                let newArr = []
                tiles.push(newArr)

                //FFFF
                newArr.push(createTiles({type: "flower", amount: 4}))

                //888
                newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 3}))

                //888
                newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 3}))

                //9999
                newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 4}))
            })
        })

        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //FF 444 5555 666 10 - 1 or 3 suits (25 points)
    function(tiles = []) {
        allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
            allSuits.forEach((suit) => {
                let newArr = []
                tiles.push(newArr)

                //FF
                newArr.push(createTiles({type: "flower", amount: 2}))

                //444
                newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 3}))

                //5555 666
                newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 4}))
                newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))

                //10
                newArr.push(createTiles({type: suitOrder[2], value: 1, amount: 1}))
                newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
            })
        })

        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //45.2543 N 36.2145 E - any types for all (25 points, concealed)
    //We can use any types as single tiles here because EVERY NUMBERED TILE is an any type.
    //Therefore, the greedy matching is not an issue.
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        newArr.push(createTiles({type: "any", value: 4, amount: 1}))
        newArr.push(createTiles({type: "any", value: 5, amount: 1}))

        newArr.push(createTiles({type: "any", value: 2, amount: 1}))
        newArr.push(createTiles({type: "any", value: 5, amount: 1}))
        newArr.push(createTiles({type: "any", value: 4, amount: 1}))
        newArr.push(createTiles({type: "any", value: 3, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))

        newArr.push(createTiles({type: "any", value: 3, amount: 1}))
        newArr.push(createTiles({type: "any", value: 6, amount: 1}))

        newArr.push(createTiles({type: "any", value: 2, amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "any", value: 4, amount: 1}))
        newArr.push(createTiles({type: "any", value: 5, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

        return {
            tiles,
            score: 25,
            concealed: true
        }
    },
].map((func, index) => {
    let output = func()
    output.section = "Do the Numbers"
    output.sectionIndex = index + 1 //Sections indexed starting at 1. 
    output.handName = `${output.section} #${output.sectionIndex}`
    return new CardHand(output)
})

