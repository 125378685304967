const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, allSuitArrangementsWithSingleSuits, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, combinations, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	function(tiles = []) {
	//A2OMIC 22 77 33 55 - 35 points, 4 diff numbered pairs.

		combinations(allOptions, 4).forEach((nums) => {
			let newArr = []
			tiles.push(newArr)

			//A2OMIC
			newArr.push(createTiles({type: "any", value: "any", amount: 1}))
			newArr.push(createTiles({type: "any", value: 2, amount: 1}))
			newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
			newArr.push(createTiles({type: "any", value: 1, amount: 1}))
			newArr.push(createTiles({type: "character", value: "any", amount: 1}))

			//22 77 33 55
			for (let i=0;i<4;i++) {
				newArr.push(createTiles({type: "any", value: nums[i], amount: 2}))
			}
		})

		return {
			tiles,
			score: 35,
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Atomic"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

