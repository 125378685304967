const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, allSuitArrangementsWithSingleSuits, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, combinations, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
    //A123456789 2025 - concealed, 50 points, 1 suit tricolor year.
    function(tiles = []) {
        allSuits.forEach((suit) => {
            allSuitArrangements.forEach((suitOrder) => {
                let newArr = []
                tiles.push(newArr)

                //A123456789
                newArr.push(createTiles({type: "any", value: "any", amount: 1}))
                for (let i=1;i<=9;i++) {
                    newArr.push(createTiles({type: suit, value: i, amount: 1}))
                }

                //2025
                newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
                newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
                newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
                newArr.push(createTiles({type: suitOrder[2], value: 5, amount: 1}))
            })
        })

        return {
            tiles,
            score: 50,
            concealed: true,
        }
    },

].map((func, index) => {
    let output = func()
    output.section = "Sub Atomic"
    output.sectionIndex = index + 1 //Sections indexed starting at 1. 
    output.handName = `${output.section} #${output.sectionIndex}`
    return new CardHand(output)
})

