const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, allSuitArrangementsWithSingleSuits, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, combinations, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
    //333 6 9999 3 6666 9 (2 suits)
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //333 6 9999
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
            newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 4}))

            //3 6666 9
            newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))
            newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 1}))
        })

        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //6 333 WWW DDD 999 6 - 1 or 3 suits (25 points)
    function(tiles = []) {
        allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //6 (suit 1)
            newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))

            //333 (suit 2)
            newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 3}))

            //WWW
            newArr.push(createTiles({type: "wind", value: "any", amount: 3}))

            //DDD
            newArr.push(createTiles({type: "dragon", value: "any", amount: 3}))

            //999 (suit 3)
            newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))

            //6 (suit 1)
            newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
        })

        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },
    //2025 PPP 3 666 999 - tricolor year, 1 suit (30 points)
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            allSuits.forEach((suit) => {
                let newArr = []
                tiles.push(newArr)

                //2025
                newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
                newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
                newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
                newArr.push(createTiles({type: suitOrder[2], value: 5, amount: 1}))

                //PPP
                newArr.push(createTiles({type: "any", value: "any", amount: 3}))

                //3 666 999
                newArr.push(createTiles({type: suit, value: 3, amount: 1}))
                newArr.push(createTiles({type: suit, value: 6, amount: 3}))
                newArr.push(createTiles({type: suit, value: 9, amount: 3}))
            })
        })

        return {
            tiles,
            score: 30,
            concealed: false,
        }
    },
    //MJ KKKK 33 666 999 - 1 or 3 suits (35 points)
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //MJ
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "joker", amount: 1}))

            //KKKK
            newArr.push(createTiles({type: "any", value: "any", amount: 4}))

            //33
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))

            //666
            newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))

            //999
            newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
        })

        allSuits.forEach((suit) => {
            let newArr = []
            tiles.push(newArr)

            //MJ
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "joker", amount: 1}))

            //KKKK
            newArr.push(createTiles({type: "any", value: "any", amount: 4}))

            //33
            newArr.push(createTiles({type: suit, value: 3, amount: 2}))

            //666
            newArr.push(createTiles({type: suit, value: 6, amount: 3}))

            //999
            newArr.push(createTiles({type: suit, value: 9, amount: 3}))
        })

        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
    //MAMBA 333 666 999 - 1 or 3 suits (35 points)
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //MAMBA
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "any", value: "any", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
            newArr.push(createTiles({type: "any", value: "any", amount: 1}))

            //333
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))

            //666
            newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))

            //999
            newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
        })

        allSuits.forEach((suit) => {
            let newArr = []
            tiles.push(newArr)

            //MAMBA
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "any", value: "any", amount: 1}))
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
            newArr.push(createTiles({type: "any", value: "any", amount: 1}))

            //333
            newArr.push(createTiles({type: suit, value: 3, amount: 3}))

            //666
            newArr.push(createTiles({type: suit, value: 6, amount: 3}))

            //999
            newArr.push(createTiles({type: suit, value: 9, amount: 3}))
        })

        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },
].map((func, index) => {
    let output = func()
    output.section = "Snake Charmer"
    output.sectionIndex = index + 1 //Sections indexed starting at 1. 
    output.handName = `${output.section} #${output.sectionIndex}`
    return new CardHand(output)
})

