const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, allSuitArrangementsWithSingleSuits, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
    //AA KKKK ING COBRA
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //AA
        newArr.push(createTiles({type: "any", value: "any", amount: 2}))

        //KKKK
        newArr.push(createTiles({type: "any", value: "any", amount: 4}))

        //ING
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "green", amount: 1}))

        //COBRA
        newArr.push(createTiles({type: "character", value: "any", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
        newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))

        return {
            tiles,
            score: 50,
            concealed: false
        }
    },
    //DIAMOND BAC KKKK
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //DIAMOND
        newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))

        //BAC
        newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "character", value: "any", amount: 1}))

        //KKKK
        newArr.push(createTiles({type: "any", value: "any", amount: 4}))

        return {
            tiles,
            score: 50,
            concealed: false
        }
    },
    //JM PPP AND SCREAM
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //JM
        newArr.push(createTiles({type: "joker", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))

        //PPP
        newArr.push(createTiles({type: "any", value: "any", amount: 3}))

        //AND
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))

        //SCREAM
        newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
        newArr.push(createTiles({type: "character", value: "any", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))

        return {
            tiles,
            score: 50,
            concealed: false
        }
    },
    //MMJ IS FN 2 PPP L AA - 50 points
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //MMJ
        newArr.push(createTiles({type: "wind", value: "west", amount: 2}))
        newArr.push(createTiles({type: "joker", amount: 1}))

        //IS
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

        //FN
        newArr.push(createTiles({type: "flower", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))

        //2
        newArr.push(createTiles({type: "any", value: 2, amount: 1}))

        //PPP
        newArr.push(createTiles({type: "any", value: "any", amount: 3}))

        //L
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))

        //AA
        newArr.push(createTiles({type: "any", value: "any", amount: 2}))

        return {
            tiles,
            score: 50,
            concealed: false
        }
    },
    //BILD A WALL FOR MJ (75 points, concealed)
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //BILD
        newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))

        //A
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))

        //WALL
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: 7, amount: 2}))

        //FOR
        newArr.push(createTiles({type: "flower", value: 1, amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))

        //MJ
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        newArr.push(createTiles({type: "joker", amount: 1}))

        return {
            tiles,
            score: 75,
            concealed: true
        }
    },
    //BOA CONS2RIC2OR (75 points, concealed)
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //BOA
        newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
        newArr.push(createTiles({type: "any", value: "any", amount: 1}))

        //CONS2RIC2OR
        newArr.push(createTiles({type: "character", value: "any", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
        newArr.push(createTiles({type: "any", value: 2, amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "character", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: 2, amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))

        return {
            tiles,
            score: 75,
            concealed: true
        }
    },
    //MEDICINE SIMBOL
    function(tiles = []) {
        let newArr = []
        tiles.push(newArr)

        //MEDICINE
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "character", value: "any", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
        newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

        //SIMBOL
        newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
        newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
        newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
        newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
        newArr.push(createTiles({type: "any", value: 7, amount: 1}))

        return {
            tiles,
            score: 75,
            concealed: true
        }
    },
].map((func, index) => {
    let output = func()
    output.section = "MJ Antidote"
    output.sectionIndex = index + 1 //Sections indexed starting at 1. 
    output.handName = `${output.section} #${output.sectionIndex}`
    return new CardHand(output)
})

