const Card = require("../Card.js")

class ImplementedCard extends Card {
	name = "2025 Marvelous Mah Jongg"

	generateHands() {
		let output = []

		output.push(...require("./snake-bit.js"))
		output.push(...require("./snake-eyes.js"))
		output.push(...require("./atomic.js"))
		output.push(...require("./sub-atomic.js"))
		output.push(...require("./dead-ringer.js"))
		output.push(...require("./snake-charmer.js"))
		output.push(...require("./do-the-numbers.js"))
		output.push(...require("./on-the-straight-and-narrow.js"))
		output.push(...require("./anaconda.js"))
		output.push(...require("./mj-antidote.js"))

		return output
	}
}

module.exports = new ImplementedCard()