const Tile = require("../../../lib/Tile")
const {setSrcForTile} = require("../setSrcForTile");

Tile.prototype.getTileSrc = function() {
	if (!window.tileSet) {
		// console.warn("No Tileset Available"); 
		return;
	}
	return window.tileSet.getTileSrc(this.faceDown ? "face-down" : this.type, this.value)
}

Tile.prototype.createImageElem = function createImageElem(options = {}) {
	//There appear to be occasional issues where the tile src becomes corrupted.
	//Exactly what causes this is unknown, but it is a common enough user complaint it needs to be handled.
	//To handle it, we will

	let img = document.createElement("img")
	setSrcForTile(img, this)

	img.title = this.getTileName(options.gameStyle)
	return img
}

module.exports = Tile
