const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, allSuitArrangementsWithSingleSuits, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, combinations, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//222 4 6 888 444 666 (3 suits) - 25 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			//222 4
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))

			//6 888
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 3}))

			//444 666
			newArr.push(createTiles({type: suitOrder[2], value: 4, amount: 3}))
			newArr.push(createTiles({type: suitOrder[2], value: 6, amount: 3}))
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	//222 444 25 666 888 - 1 or 3 suits - 25 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			//222 444
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 3}))

			//25
			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 1}))

			//666 888
			newArr.push(createTiles({type: suitOrder[2], value: 6, amount: 3}))
			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 3}))
		})

		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			//222 444
			newArr.push(createTiles({type: suit, value: 2, amount: 3}))
			newArr.push(createTiles({type: suit, value: 4, amount: 3}))

			//25
			newArr.push(createTiles({type: suit, value: 2, amount: 1}))
			newArr.push(createTiles({type: suit, value: 5, amount: 1}))

			//666 888
			newArr.push(createTiles({type: suit, value: 6, amount: 3}))
			newArr.push(createTiles({type: suit, value: 8, amount: 3}))
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	//AS KKKK 4 A 2 22 222 - 3 suits, same even - 25 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			evenOptions.forEach((evenVal) => {
				let newArr = []
				tiles.push(newArr)

				//AS
				newArr.push(createTiles({type: "any", value: "any", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

				//KKKK
				newArr.push(createTiles({type: "any", value: "any", amount: 4}))

				//4 A
				newArr.push(createTiles({type: "any", value: 4, amount: 1}))
				newArr.push(createTiles({type: "any", value: "any", amount: 1}))

				//2 22 222
				newArr.push(createTiles({type: suitOrder[0], value: evenVal, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: evenVal, amount: 2}))
				newArr.push(createTiles({type: suitOrder[2], value: evenVal, amount: 3}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
	//FF 444 DDD 888 EEE - diff evens, 2 suits, any wind - 25 points
		//This could be optimized but the duplicate remover should be fast enough.
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			evenOptions.forEach((evenVal1) => {
				evenOptions.forEach((evenVal2) => {
					if (evenVal1 === evenVal2) {return}

					let newArr = []
					tiles.push(newArr)

					//FF
					newArr.push(createTiles({type: "flower", amount: 2}))

					//444
					newArr.push(createTiles({type: suitOrder[0], value: evenVal1, amount: 3}))

					//DDD
					newArr.push(createTiles({type: "dragon", value: "any", amount: 3}))

					//888
					newArr.push(createTiles({type: suitOrder[1], value: evenVal2, amount: 3}))

					//EEE
					newArr.push(createTiles({type: "wind", value: "any", amount: 3}))
				})
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},

		//MJ PPP KKKK 666 88 - 1 suit - 35 points
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			//MJ
			newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
			newArr.push(createTiles({type: "joker", amount: 1}))

			//PPP
			newArr.push(createTiles({type: "any", value: "any", amount: 3}))

			//KKKK
			newArr.push(createTiles({type: "any", value: "any", amount: 4}))

			//666 88
			newArr.push(createTiles({type: suit, value: 6, amount: 3}))
			newArr.push(createTiles({type: suit, value: 8, amount: 2}))
		})

		return {
			tiles,
			score: 35,
			concealed: false,
		}
	},

		//FFF 2468 PPP 2468 - 2 suits, 35 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			//FFF
			newArr.push(createTiles({type: "flower", amount: 3}))

			//2468
			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 1}))

			//PPP
			newArr.push(createTiles({type: "any", value: "any", amount: 3}))

			//2468
			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 1}))

		})

		return {
			tiles,
			score: 35,
			concealed: false,
		}
	},

		//CLEO PPP A 222 R AA - 40 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			//CLEO
			newArr.push(createTiles({type: "character", value: "any", amount: 1}));
			newArr.push(createTiles({type: "any", value: 7, amount: 1}));
			newArr.push(createTiles({type: "wind", value: "east", amount: 1}));
			newArr.push(createTiles({type: "dragon", value: "white", amount: 1}));

			//PPP
			newArr.push(createTiles({type: "any", value: "any", amount: 3}));

			//A
			newArr.push(createTiles({type: "any", value: "any", amount: 1}));

			//222
			newArr.push(createTiles({type: "any", value: "2", amount: 3}));

			//R
			newArr.push(createTiles({type: "dragon", value: "red", amount: 1}));

			//AA
			newArr.push(createTiles({type: "any", value: "any", amount: 2}));
		})

		return {
			tiles,
			score: 40,
			concealed: false,
		}
	},

].map((func, index) => {
	let output = func()
	output.section = "Snake Bit"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

