const Pretty = require("../../../lib/Pretty")
const {setSrcForTile} = require("../setSrcForTile");

Pretty.prototype.getTileSrc = function() {
	if (!window.tileSet) {
		// console.warn("No Tileset Available"); 
		return;
	}
	return window.tileSet.getTileSrc(this.seasonOrFlower, this.value)
}

Pretty.prototype.createImageElem = function createImageElem(options = {}) {
	let img = document.createElement("img")
	setSrcForTile(img, this)

	img.title = options.title
	return img
}

module.exports = Pretty

