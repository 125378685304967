const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, allSuitArrangementsWithSingleSuits, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
		//22 333 4444 55 666 (consec, 1 or 3 suits)
	function(tiles = []) {
		allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
			allOptions.slice(0, -4).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				//22 333
				newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: offset + 1, amount: 3}))

				//4444
				newArr.push(createTiles({type: suitOrder[1], value: offset + 2, amount: 4}))

				//55 666
				newArr.push(createTiles({type: suitOrder[2], value: offset + 3, amount: 2}))
				newArr.push(createTiles({type: suitOrder[2], value: offset + 4, amount: 3}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
		//1 222 3 4 555 6 7 888 (consec, 1 or 3 suits)
	function(tiles = []) {
		allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
			allOptions.slice(0, -7).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				//1 222 3
				newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: offset + 1, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: offset + 2, amount: 1}))

				//4 555 6
				newArr.push(createTiles({type: suitOrder[1], value: offset + 3, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset + 4, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: offset + 5, amount: 1}))

				//7 888
				newArr.push(createTiles({type: suitOrder[2], value: offset + 6, amount: 1}))
				newArr.push(createTiles({type: suitOrder[2], value: offset + 7, amount: 3}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
		//20 777 EEE 9999 25 (1 or 3 suits, and int. run) - 30 points
	function(tiles = []) {
		allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
			allOptions.slice(0, -2).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				//20
				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))

				//777
				newArr.push(createTiles({type: suitOrder[1], value: offset + 0, amount: 3}))

				//EEE
				newArr.push(createTiles({type: "wind", value: "any", amount: 3}))

				//9999
				newArr.push(createTiles({type: suitOrder[1], value: offset + 2, amount: 4}))

				//25
				newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[2], value: 5, amount: 1}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: false,
		}
	},
//AA 1 1 1 222 333 4 4 4 (3 suits, consec run,) - 35 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0, -3).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				//AA
				newArr.push(createTiles({type: "any", value: "any", amount: 2}))

				//1 1 1
				newArr.push(createTiles({type: "circle", value: offset + 0, amount: 1}))
				newArr.push(createTiles({type: "character", value: offset + 0, amount: 1}))
				newArr.push(createTiles({type: "bamboo", value: offset + 0, amount: 1}))

				//222
				newArr.push(createTiles({type: suitOrder[0], value: offset + 1, amount: 3}))

				//333
				newArr.push(createTiles({type: suitOrder[1], value: offset + 2, amount: 3}))

				//4 4 4
				newArr.push(createTiles({type: "circle", value: offset + 3, amount: 1}))
				newArr.push(createTiles({type: "character", value: offset + 3, amount: 1}))
				newArr.push(createTiles({type: "bamboo", value: offset + 3, amount: 1}))
			})
		})

		return {
			tiles,
			score: 35,
			concealed: false,
		}
	},

		//MJ 111 PPP 333 444 - 1 or 3 suits, int run (35 points)
	function(tiles = []) {
		allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
			allOptions.slice(0, -3).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				//MJ
				newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
				newArr.push(createTiles({type: "joker", amount: 1}))

				//111
				newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 3}))

				//PPP
				newArr.push(createTiles({type: "any", value: "any", amount: 3}))

				//333
				newArr.push(createTiles({type: suitOrder[1], value: offset + 2, amount: 3}))

				//444
				newArr.push(createTiles({type: suitOrder[2], value: offset + 3, amount: 3}))
			})
		})

		return {
			tiles,
			score: 35,
			concealed: false,
		}
	},
		//KKKK 12345 12345 (any consec, 2 suits) - 35 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0, -4).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				//KKKK
				newArr.push(createTiles({type: "any", value: "any", amount: 4}))

				//12345
				newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: offset + 1, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: offset + 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: offset + 3, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: offset + 4, amount: 1}))

				//12345
				newArr.push(createTiles({type: suitOrder[1], value: offset + 0, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset + 1, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset + 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset + 3, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset + 4, amount: 1}))
			})
		})

		return {
			tiles,
			score: 35,
			concealed: false,
		}
	},
		//NEWS 0 2468 13579 - 2 suits (40 points, concealed)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0, -3).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				//NEWS
				newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

				//0
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))

				//2488
				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 1}))


				//13579
				newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 1}))

			})
		})

		return {
			tiles,
			score: 40,
			concealed: true,
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "On the Straight and Narrow"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

