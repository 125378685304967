const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, allSuitArrangementsWithSingleSuits, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, combinations, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
    //F 1 333 555 999 999 - 3 suits (25 points)
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //F
            newArr.push(createTiles({type: "flower", amount: 1}))

            //1 333 555
            newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
            newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 3}))

            //999
            newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 3}))

            //999
            newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
        })

        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },

    //111 333 25 777 999 (1 or 3 suits) - 25 points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //111 333
            newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 3}))
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))

            //25
            newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 1}))

            //777 999
            newArr.push(createTiles({type: suitOrder[2], value: 7, amount: 3}))
            newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 3}))
        })

        allSuits.forEach((suit) => {
            let newArr = []
            tiles.push(newArr)

            //111 333
            newArr.push(createTiles({type: suit, value: 1, amount: 3}))
            newArr.push(createTiles({type: suit, value: 3, amount: 3}))

            //25
            newArr.push(createTiles({type: suit, value: 2, amount: 1}))
            newArr.push(createTiles({type: suit, value: 5, amount: 1}))

            //777 999
            newArr.push(createTiles({type: suit, value: 7, amount: 3}))
            newArr.push(createTiles({type: suit, value: 9, amount: 3}))
        })


        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },

    //AS KKKK 4 A 5 55 555 (same odd, 3 suits) - 25 points
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            oddOptions.forEach((val) => {
                let newArr = []
                tiles.push(newArr)

                //AS
                newArr.push(createTiles({type: "any", value: "any", amount: 1}))
                newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

                //KKKK
                newArr.push(createTiles({type: "any", value: "any", amount: 4}))

                //4 A
                newArr.push(createTiles({type: "any", value: 4, amount: 1}))
                newArr.push(createTiles({type: "any", value: "any", amount: 1}))

                //5 55 555
                newArr.push(createTiles({type: suitOrder[0], value: val, amount: 1}))
                newArr.push(createTiles({type: suitOrder[1], value: val, amount: 2}))
                newArr.push(createTiles({type: suitOrder[2], value: val, amount: 3}))
            })
        })

        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },

    //FF 333 DDD 777 NNN - 2 diff odds, 2 suits (25 points)
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            oddOptions.forEach((val) => {
                oddOptions.forEach((val2) => {
                    if (val === val2) return
                    let newArr = []
                    tiles.push(newArr)

                    //FF
                    newArr.push(createTiles({type: "flower", amount: 2}))

                    //333
                    newArr.push(createTiles({type: suitOrder[0], value: val, amount: 3}))

                    //DDD
                    newArr.push(createTiles({type: "dragon", value: "any", amount: 3}))

                    //777
                    newArr.push(createTiles({type: suitOrder[1], value: val2, amount: 3}))

                    //NNN
                    newArr.push(createTiles({type: "wind", value: "any", amount: 3}))
                })
            })
        })

        return {
            tiles,
            score: 25,
            concealed: false,
        }
    },

    //MJ PPP KKKK 333 55 - 1 suit, 35 points
    function(tiles = []) {
        allSuits.forEach((suit) => {
            let newArr = []
            tiles.push(newArr)

            //MJ
            newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
            newArr.push(createTiles({type: "joker", amount: 1}))

            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))

            //KKKK
            newArr.push(createTiles({type: "any", value: "any", amount: 4}))

            //333 55
            newArr.push(createTiles({type: suit, value: 3, amount: 3}))
            newArr.push(createTiles({type: suit, value: 5, amount: 2}))
        })

        return {
            tiles,
            score: 35,
            concealed: false,
        }
    },

    //F 13579 PPP 13579 - 2 suits (40 points)
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //F
            newArr.push(createTiles({type: "flower", amount: 1}))

            //13579
            newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 7, amount: 1}))
            newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 1}))

            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))

            //13579
            newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 1}))
            newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 1}))
        })

        return {
            tiles,
            score: 40,
            concealed: false,
        }
    },

    //RO LLL I PPP O LLL I - 2 suits (40 points)
    function(tiles = []) {
        allSuitArrangements.forEach((suitOrder) => {
            let newArr = []
            tiles.push(newArr)

            //RO
            newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
            newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))

            //LLL
            newArr.push(createTiles({type: suitOrder[0], value: 7, amount: 3}))

            //I
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))

            //PPP
            newArr.push(createTiles({type: "any", value: "any", amount: 3}))

            //O
            newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))

            //LLL
            newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 3}))

            //I
            newArr.push(createTiles({type: "any", value: 1, amount: 1}))
        })

        return {
            tiles,
            score: 40,
            concealed: false,
        }
    },


].map((func, index) => {
    let output = func()
    output.section = "Snake Eyes"
    output.sectionIndex = index + 1 //Sections indexed starting at 1. 
    output.handName = `${output.section} #${output.sectionIndex}`
    return new CardHand(output)
})

