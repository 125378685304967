let timeout;
function dispatchTileSetUpdatedCached() {
  if (timeout) {
    clearTimeout(timeout)
  }
  timeout = setTimeout(function() {
    window.dispatchEvent(new Event("tileSetUpdated"))
    timeout = null
  }, 1000)
}


/*
* Set the src for a tile element.
* If the URL errors, we will purge the URL and attempt to reload the tile.
* Note that it is possible that the imgElem will be modified. If it is modified, we will not touch it.
* */
function setSrcForTile(imgElem: HTMLImageElement, tile) {
  let initialSrc = tile.getTileSrc()

  if (!(typeof initialSrc === "string")) {
    console.warn("Not string")
    return
  }

  imgElem.onerror = async function() {
    if (imgElem.src === initialSrc) {
      if (tile.type === "pretty") {
        //@ts-ignore
        await window.tileSet?.revokeTileSrc(tile.seasonOrFlower, tile.value)
      }
      else {
        //@ts-ignore
        await window.tileSet?.revokeTileSrc(this.faceDown ? "face-down" : tile.type, tile.value)
      }
      await tile.getTileSrc()

      console.log("Tile detected as broken, scheduling tileset reload")
      dispatchTileSetUpdatedCached()
    }
  }
  imgElem.src = initialSrc
}

export {setSrcForTile}